// 備考最大文字数
const MAX_REMARKS = 255
// １回のメッセージ取得件数
const MESSAGES_GET_PER_PAGE = 20
// 服薬フォロー中のメッセージ最大上限数
const MAX_MESSAGE_LENGTH = 512
// メッセージの送信予約最大日数
const RESERVED_SEND_MESSAGE_MAX_DAY = 30
// 定型文選択肢
const DEFAULT_MESSAGE_TEMPLETE = { id: 0, value: '定型文を選択' }

export {
  MAX_REMARKS,
  MESSAGES_GET_PER_PAGE,
  MAX_MESSAGE_LENGTH,
  RESERVED_SEND_MESSAGE_MAX_DAY,
  DEFAULT_MESSAGE_TEMPLETE,
}
