/* eslint-disable */
import { Dispatch } from 'redux'
import { Domain } from 'core'
import * as Api from 'libs/api'
import { setMessageTemplates } from 'core/modules/messageTemplates'
import { DEFAULT_MESSAGE_TEMPLETE } from 'constants/value'
import { MessageTemplates } from 'core/modules'

type ApiResponse = { messageTemplates: Domain.MedicationFollowUp.MessageTemplate[] | [] }

function didCallSetSendGetMessageTemplatesApi(dispatch: Dispatch, data: ApiResponse | null | Error) {
  if (data instanceof Error) {
    return
  }
  const initialData = [DEFAULT_MESSAGE_TEMPLETE]

  if (data && data?.messageTemplates.length > 0) {
    dispatch(setMessageTemplates([...initialData, ...data.messageTemplates]))
  } else {
    dispatch(setMessageTemplates(initialData))
  }
}

export function sendGetMessageTemplates(idToken: string | undefined, shopId: string | null) {
  return async (dispatch: Dispatch) => {
    // Send GET API
    await Api.callApi(
      Api.HttpMethod.get,
      `shops/${shopId}/message-templates`,
      idToken,
      null,
      dispatch,
      didCallSetSendGetMessageTemplatesApi,
    )
  }
}
